import React, { useEffect } from "react";
import check from "../../assets/Images/check.png";
import { useLocation } from "react-router-dom";
import "../../assets/css/style.css";
import { useSelector } from "react-redux";
import { SessionState } from "../../redux/sessionSlice";

export default function Confirmation() {
  const location = useLocation();
  const passedData = location.state;
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.dynamicLink
  );

  useEffect(() => {
    setTimeout(() => {
      window.location.href = UpdatedLocalData;
      window.close();
    }, 12000); // 5000 milliseconds = 5 seconds
  }, []);

  const renderContent = () => {
    if (
      passedData ==
      "Credit Rent Boost Tenant Already Registered. Payment Already Received."
    ) {
      return (
        <>
          <p className="text-center text-2xl poppins-semibold text-white leading-8">
            {passedData}
          </p>
          <p className="text-center poppins-medium text-white leading-8 mt-8">
            Thank you for your payment. Your transaction has already been
            processed.
          </p>
          <div className="center-content">
            <img
              src={check}
              className="w-28 h-28 sm:w-56 sm:h-56 mt-4 sm:mt-8"
              alt="Success Icon"
            />
          </div>
          {/* Apply the custom CSS class */}
        </>
      );
    } else {
      return (
        <>
          <p className="text-center text-2xl poppins-semibold text-white leading-8">
            Payment Successful!
          </p>
          <p className="text-center poppins-medium text-white leading-8 mt-5 ml-5 mr-5">
            {passedData}
          </p>
          <div className="center-content">
            <img
              src={check}
              className="w-28 h-28 sm:w-56 sm:h-56 mt-4 sm:mt-8"
              alt="Success Icon"
            />
          </div>
        </>
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-image-confirmation">
      <div className="grid">{renderContent()}</div>
    </div>
  );
}
