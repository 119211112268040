import React, { useEffect } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import { Config } from "../../Utils/AppConstants";
import outer_eclipse from "../../assets/Images/cancelled_outer.png";
import money_off from "../../assets/Images/money_off.png";
import { useSelector } from "react-redux";
import "./ReactivatePlanScreen.css"; // Import your CSS file for animations
import { SessionState } from "../../redux/sessionSlice";

const ReactivatePlanScreen = () => {
  const gradientColorStart = useSelector(
    (state: SessionState) => state?.session.filteredFintechData
  );
  const activeSubscription = useSelector(
    (state: SessionState) => state?.session.filteredActiveSubscription
  );
  const gradientColorStart1 =
    gradientColorStart.length > 0
      ? gradientColorStart[0].gradientColorStart
      : "#000000"; // Fallback color
  // const btColor =
  //   gradientColorStart.length > 0
  //     ? gradientColorStart[0].gradientColorStart
  //     : "#000000"; // Fallback color
  const fintechIcon =
    gradientColorStart.length > 0 ? gradientColorStart[0].fintechIcon : ""; // Fallback for icon
  // const hexToRgb = (hex: string) => {
  //   // Remove the leading # if it's there
  //   hex = hex.replace(/^#/, "");

  //   // Parse the r, g, b values
  //   const bigint = parseInt(hex, 16);
  //   const r = (bigint >> 16) & 255;
  //   const g = (bigint >> 8) & 255;
  //   const b = bigint & 255;

  //   // Normalize the RGB values to be between 0 and 1 (feColorMatrix expects normalized values)
  //   return { r: r / 255, g: g / 255, b: b / 255 };
  // };

  // const gradientRgb = hexToRgb(gradientColorStart1);

  // Function to format the date
  // Function to format the date
  // Function to format the date
  const formatDate = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric", // Correctly typed as 'numeric'
      month: "long", // Correctly typed as 'long'
      day: "numeric", // Correctly typed as 'numeric'
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleManageTrialClick = () => {};

  useEffect(() => {
    console.log(
      // "Grdienfbgmdfgndf,mgndf,gnf" + JSON.stringify(gradientColorStart)

      console.log(
        "Active stored SUbscription" + JSON.stringify(activeSubscription)
      )
    );
  }, []);

  return (
    <div className="flex justify-center items-center overflo h-screen">
      <div
        className="w-full h-full flex flex-col bg-gradient-guac text-white relative z-20"
        style={{
          background: `linear-gradient(${gradientColorStart1} 0%, rgb(113, 198, 1) 7%, rgb(174, 221, 1) 20%, rgb(174, 221, 1) 100%)`,
        }}
      >
        <div className="flex items-center justify-between p-4 mt-2">
          <span className="back-arrow">
            <img
              alt="back"
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
            />
          </span>
        </div>

        <div
          className={`flex-grow relative rounded-t-3xl mt-6 p-4 flex flex-col ${
            Config.isLight ? "bg-[#EBECF0]" : "bg-[#121212]"
          }`}
        >
          {" "}
          <div className="relative mb-2">
            <img
              alt="Guac"
              src={fintechIcon}
              className="absolute -top-10 opacity-90 left-0 z-50 w-20 h-15 imgicon"
            />

            <span className="custom-span font-poppins absolute left-20 -top-2 text-[#FFFFFF]">
              Guac
            </span>
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex-grow">
              <div className=" rounded-3xl shadow-lg p-6 w-[22rem] text-center">
                {/* Icon Section */}
                <div className="flex items-center justify-center">
                  <div className="relative p-4 rounded-full">
                    <img
                      src={outer_eclipse} // replace with your outer circle image
                      alt="Outer Circle"
                      className="h-40 w-40" // Adjust size as needed
                    />
                    <img
                      src={money_off} // replace with your credit card image
                      alt="money Card"
                      className="absolute inset-0 h-24 w-24 m-auto p-2" // Center the credit card and add padding
                    />
                  </div>
                </div>

                {/* Free Trial Info */}
                <p className="text-[#DF4949] text-center font-poppins text-[14px] font-semibold leading-[20px] tracking[-0.1px]">
                  <span className="mr-2">•</span>Cancelled
                </p>

                <h1 className="text-xl text-white font-semibold">
                  {activeSubscription?.subscriptionTitle}
                </h1>

                {/* Trial end date */}
                <div className="bg-[#2C2C2C] rounded-lg  p-2 relative mt-4">
                  <div className="absolute top-2 left-2">
                    {/* Replace the following SVG with your own */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                      />
                    </svg>
                  </div>

                  <div className="overflow-hidden text-center">
                    <p className="trial-end-text">
                      Your free trial will end on
                    </p>
                    <p className="trial-date-text">
                      {activeSubscription?.subscriptionEndDate
                        ? formatDate(activeSubscription?.subscriptionEndDate)
                        : "N/A"}
                    </p>

                    <p className="trial-message-recativate">
                      After that, you will no longer have access and your
                      payment method on file will not be charged. Please help us
                      improve by answering a quick survey about why you
                      canceled.
                    </p>

                    <button
                      className="mt-4 w-[162px] h-[42px] flex-shrink-0 bg-[#4E4E4E] text-white font-poppins text-[11px] font-semibold leading-[20px] tracking[-0.1px] text-center rounded-[10px]"
                      style={{ textTransform: "capitalize" }}
                    >
                      Give Feedback
                    </button>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="mt-6 mb-4">
                  <button
                    onClick={handleManageTrialClick}
                    className="reactivate-button"
                  >
                    <span className="button-text-style-reactivate">
                      REACTIVATE PLAN
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {/* <CustomSlider slides={slides} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactivatePlanScreen;
