import React, { useEffect, useState } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import { Config } from "../../Utils/AppConstants";

import { useSelector } from "react-redux";
import "./SubscriptionManagement.css"; // Import your CSS file for animations
import { SessionState } from "../../redux/sessionSlice";
import visa from "../../assets/Images/visa.png";
import city_double_cash from "../../assets/Images/city_double_cash.png";
import check_everyday from "../../assets/Images/check_everyday.png";
import pwa_image from "../../assets/Images/pwa_image.png";
import { useNavigate } from "react-router-dom";
const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const gradientColorStart = useSelector(
    (state: SessionState) => state?.session.filteredFintechData
  );
  const activeSubscription = useSelector(
    (state: SessionState) => state?.session.filteredActiveSubscription
  );
  const [isManagingTrial, setIsManagingTrial] = useState(false);
  const gradientColorStart1 =
    gradientColorStart?.length > 0
      ? gradientColorStart[0]?.gradientColorStart
      : "#000000"; // Fallback color
  // const btColor =
  //   gradientColorStart?.length > 0
  //     ? gradientColorStart[0].gradientColorStart
  //     : "#000000"; // Fallback color
  const fintechIcon =
    gradientColorStart?.length > 0 ? gradientColorStart[0]?.fintechIcon : ""; // Fallback for icon
  const hexToRgb = (hex: string) => {
    // Remove the leading # if it's there
    hex = hex.replace(/^#/, "");

    // Parse the r, g, b values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Normalize the RGB values to be between 0 and 1 (feColorMatrix expects normalized values)
    return { r: r / 255, g: g / 255, b: b / 255 };
  };

  const gradientRgb = hexToRgb(gradientColorStart1);

  // Function to format the date
  // Function to format the date
  // Function to format the date
  const formatDate = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric", // Correctly typed as 'numeric'
      month: "long", // Correctly typed as 'long'
      day: "numeric", // Correctly typed as 'numeric'
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleManageTrialClick = () => {
    setIsManagingTrial(true);
  };

  const handleManagePayment = () => {
    navigate("/portfoliopilot");
  };

  const handlecancel = () => {
    setIsManagingTrial(false);
    // navigate("/cancelSubscription");
  };
  const handleCancelSubscription = () => {
    setIsManagingTrial(false);
    navigate("/cancelSubscription");
  };

  const getImageSource = (type: string) => {
    switch (type) {
      case "ACH":
        return check_everyday;
      case "Bank":
        return check_everyday; // Assuming Bank uses the same image as check_everyday
      case "Visa":
        return visa;
      case "CC":
        return city_double_cash;
      default:
        return pwa_image;
    }
  };

  useEffect(() => {
    console
      .log
      // "Grdienfbgmdfgndf,mgndf,gnf" + JSON.stringify(gradientColorStart)
      ();
  }, []);

  return (
    <div className="flex justify-center items-center overflo h-screen">
      <div
        className="w-full h-full flex flex-col bg-gradient-guac text-white relative z-20"
        style={{
          background: `linear-gradient(${gradientColorStart1} 0%, rgb(113, 198, 1) 7%, rgb(174, 221, 1) 20%, rgb(174, 221, 1) 100%)`,
        }}
      >
        <div className="flex items-center justify-between p-4 mt-2">
          <span className="back-arrow">
            <img
              alt="back"
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
            />
          </span>
        </div>

        <div
          className={`flex-grow relative rounded-t-3xl mt-6 p-4 flex flex-col ${
            Config.isLight ? "bg-[#EBECF0]" : "bg-[#121212]"
          }`}
        >
          {" "}
          <div className="relative mb-2">
            <img
              alt="Guac"
              src={fintechIcon}
              className="absolute -top-10 opacity-90 left-0 z-50 w-20 h-15 imgicon"
            />

            <span className="custom-span font-poppins absolute left-20 -top-2 text-[#FFFFFF]">
              Guac
            </span>
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex-grow">
              <div className=" rounded-3xl shadow-lg p-6 w-[22rem] text-center">
                {/* Icon Section */}
                <div className="flex items-center justify-center">
                  <div className="relative p-4 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="186"
                      height="186"
                      viewBox="0 0 186 186"
                      fill="none"
                    >
                      <g filter="url(#filter0_d_16343_1495)">
                        <circle cx="93" cy="89" r="58" fill="#121212" />
                        <circle
                          cx="93"
                          cy="89"
                          r="56.5"
                          stroke={gradientColorStart1}
                          strokeWidth="3"
                        />
                        {/* Nested SVG */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="72"
                          height="72"
                          x="57" // Adjust the x position (186 / 2 - 72 / 2 = 57)
                          y="47" // Adjust the y position (186 / 2 - 72 / 2 = 47)
                          viewBox="0 0 72 72"
                          fill="none"
                        >
                          <g filter="url(#filter0_i_16370_1570)">
                            <mask
                              id="mask0_16370_1570"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="72"
                              height="72"
                            >
                              <rect width="72" height="72" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_16370_1570)">
                              <path
                                d="M54.8363 50.4285V43.4422C54.8363 43.0807 54.7055 42.7692 54.444 42.5077C54.1825 42.2462 53.871 42.1155 53.5095 42.1155C53.148 42.1155 52.8365 42.2462 52.575 42.5077C52.3135 42.7692 52.1828 43.0807 52.1828 43.4422V50.3827C52.1828 50.7442 52.248 51.0885 52.3785 51.4155C52.5095 51.7425 52.7152 52.0462 52.9958 52.3268L57.6862 57.0173C57.9478 57.2787 58.2545 57.4143 58.6065 57.4238C58.9585 57.4338 59.275 57.2982 59.556 57.0173C59.8365 56.7367 59.9768 56.4253 59.9768 56.0828C59.9768 55.7403 59.8365 55.4288 59.556 55.1483L54.8363 50.4285ZM53.556 64.9328C49.6445 64.9328 46.327 63.5837 43.6035 60.8857C40.8805 58.1878 39.519 54.883 39.519 50.9715C39.519 47.06 40.8805 43.7503 43.6035 41.0423C46.327 38.3348 49.6445 36.981 53.556 36.981C57.4365 36.981 60.7258 38.3425 63.4238 41.0655C66.1217 43.7885 67.4707 47.0905 67.4707 50.9715C67.4707 54.852 66.1217 58.149 63.4238 60.8625C60.7258 63.576 57.4365 64.9328 53.556 64.9328ZM12.9233 58.5C11.4077 58.5 10.125 57.975 9.075 56.925C8.025 55.875 7.5 54.5923 7.5 53.0768V18.9233C7.5 17.4077 8.025 16.125 9.075 15.075C10.125 14.025 11.4077 13.5 12.9233 13.5H59.0768C60.5923 13.5 61.875 14.025 62.925 15.075C63.975 16.125 64.5 17.4077 64.5 18.9233V30.5422C64.5 31.1807 64.2845 31.7155 63.8535 32.1465C63.423 32.577 62.8885 32.7922 62.25 32.7922C61.6115 32.7922 61.077 32.577 60.6465 32.1465C60.2155 31.7155 60 31.1807 60 30.5422V25.212H12V34.788H36.7215C37.456 34.788 37.9753 35.14 38.2793 35.844C38.5828 36.548 38.4693 37.1885 37.9388 37.7655C36.3193 39.454 35.046 41.427 34.119 43.6845C33.192 45.942 32.7285 48.342 32.7285 50.8845C32.7285 51.6615 32.772 52.426 32.859 53.178C32.9455 53.93 33.0847 54.654 33.2767 55.35C33.4692 56.142 33.327 56.865 32.85 57.519C32.373 58.173 31.748 58.5 30.975 58.5H12.9233Z"
                                fill={gradientColorStart1}
                              />
                            </g>
                          </g>
                          <defs>
                            <filter
                              id="filter0_i_16370_1570"
                              x="7.5"
                              y="13.5"
                              width="59.9707"
                              height="55.4336"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect1_innerShadow_16370_1570"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_16343_1495"
                          x="0"
                          y="0"
                          width="186"
                          height="186"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feMorphology
                            radius="5"
                            operator="dilate"
                            in="SourceAlpha"
                            result="effect1_dropShadow_16343_1495"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="15" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values={`0 0 0 0 ${gradientRgb.r} 0 0 0 0 ${gradientRgb.g} 0 0 0 0 ${gradientRgb.b} 0 0 0 1 0`}
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_16343_1495"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_16343_1495"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

                {/* Free Trial Info */}
                <p className="text-[#46B601] font-medium font-poppins">
                  <span className="mr-2">•</span>Free Trial in Progress
                </p>
                <h1 className="text-xl text-white font-semibold">
                  {activeSubscription?.subscriptionTitle}
                </h1>

                {/* Trial end date */}
                <div className="bg-[#2C2C2C] rounded-lg  p-2 relative mt-4">
                  <div className="absolute top-2 left-2">
                    {/* Replace the following SVG with your own */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                      />
                    </svg>
                  </div>
                  {activeSubscription?.inTrial ? (
                    <>
                      <p className="trial-end-text">
                        Your free trial will end on
                      </p>
                      <p className="trial-date-text">
                        {activeSubscription?.subscriptionEndDate
                          ? formatDate(activeSubscription?.subscriptionEndDate)
                          : "N/A"}
                      </p>

                      <p className="text-white text-center font-poppins text-xs font-semibold leading-6 tracking-tight mt-1 whitespace-nowrap">
                        <span className="opacity-50">
                          After that, you will be automatically billed{" "}
                        </span>
                        <span className="opacity-50">
                          ${activeSubscription?.recurringAmount}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="trial-end-text font-poppins">
                        Billed Monthly
                      </p>
                      <p className="custom-text-anytime-cancel">
                        <span className="opacity-50 font-poppins">
                          Cancel anytime
                        </span>
                      </p>
                    </>
                  )}
                </div>

                {/* Payment Info */}
                <div className="text-left mt-6 text-white">
                  {!isManagingTrial && (
                    <>
                      <div className="flex justify-between mb-2">
                        <span className="plan-text">Plan</span>
                        <span className="guac-gold-text">
                          {activeSubscription?.subscriptionTitle}
                        </span>
                      </div>
                      <div className="flex justify-between mb-2">
                        <span className="plan-text">Next Payment</span>
                        <span className="guac-gold-text">
                          {activeSubscription?.subscriptionEndDate
                            ? formatDate(
                                activeSubscription?.subscriptionEndDate
                              )
                            : "N/A"}
                        </span>
                      </div>
                      <div className="flex justify-between mb-2">
                        <span className="plan-text">Payment Method</span>
                        <span className="flex items-center space-x-2">
                          <span className="guac-gold-text mr-2">
                            •••• {activeSubscription?.cardNumberLast4}
                          </span>
                          <span className="bg-blue-500 text-white p-1 rounded-md text-xs">
                            <img
                              src={getImageSource(
                                activeSubscription?.paymentType
                              )}
                              alt={
                                activeSubscription?.paymentType ||
                                "Payment Method"
                              }
                              className="w-8 h-5 rounded m"
                              style={{ objectFit: "contain" }}
                            />
                          </span>
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="plan-text">Total</span>
                        <span className="guac-gold-text">
                          {activeSubscription?.recurringAmount}
                        </span>
                      </div>
                    </>
                  )}
                  {isManagingTrial && (
                    <>
                      <div className="flex justify-between">
                        <span className="plan-text">Plan</span>
                        <span className="guac-gold-text">
                          {activeSubscription?.subscriptionTitle}
                        </span>
                      </div>
                      <div className="w-full text-right mb-3">
                        <p
                          className="cancel-text"
                          style={{ color: gradientColorStart1 }}
                        >
                          Cancel Plan
                        </p>
                      </div>

                      <div className="flex justify-between mt-2">
                        <span className="plan-text">Payment Method</span>
                        <span className="flex items-center space-x-2">
                          <span className="guac-gold-text mr-2">
                            •••• {activeSubscription?.cardNumberLast4}
                          </span>
                          <span className="bg-blue-500 text-white p-1 rounded-md text-xs">
                            <img
                              src={getImageSource(
                                activeSubscription?.paymentType
                              )}
                              alt={
                                activeSubscription?.paymentType ||
                                "Payment Method"
                              }
                              className="w-8 h-5 rounded m"
                              style={{ objectFit: "contain" }}
                            />
                          </span>
                        </span>
                      </div>
                      <div
                        className="w-full text-right  "
                        onClick={handleManagePayment}
                      >
                        <p
                          className="cancel-text"
                          style={{ color: gradientColorStart1 }}
                        >
                          Change Payment Method
                        </p>
                      </div>
                    </>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="mt-6">
                  {!isManagingTrial ? (
                    <div className="mt-6 flex space-x-2">
                      <button
                        className="cancel-trial-btn-txt"
                        onClick={handleCancelSubscription}
                      >
                        Cancel Trial
                      </button>

                      <button
                        onClick={handleManageTrialClick}
                        className="manage-trial-btn"
                        style={{ backgroundColor: gradientColorStart1 }} // Use dynamic color
                      >
                        Manage Trial
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={handlecancel}
                      className="cancel-trial-btn w-full font-poppins"
                    >
                      CANCEL
                    </button>
                  )}
                </div>
              </div>
              {/* <CustomSlider slides={slides} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagement;
