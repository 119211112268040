import { createAction, createSlice } from '@reduxjs/toolkit';

export const setToken = createAction<string>('token/set');

const tokenSlice = createSlice({
  name: 'token',
  initialState: null as string | null,
  reducers: {
    clearToken: () => null, // No state parameter needed
  },
  extraReducers: (builder) => {
    builder.addCase(setToken, (state, action) => {
      return action.payload;
    });
  },
});

export const { clearToken } = tokenSlice.actions;
export default tokenSlice.reducer;
