import React, { useState, useEffect } from "react";
import check from "../../../assets/Images/check.png";
import { clearPaymentCVVs, SessionState } from "../../../redux/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import "./GoalAdded.css"; // Import your CSS file for animations

const PortfolioGoal = () => {
  const dispatch = useDispatch();
  const [isPaymentUpdate, setisPaymentUpdate] = useState(false);
  const [displayText, setDisplayText] = useState(""); // Initially empty
  const [subscriptionTitle, setSubscriptionTitle] = useState("");
  const [animateDone, setAnimateDone] = useState(false); // State for animation
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility
  const [doneTextDisappear, setDoneTextDisappear] = useState(false); // State to track if "DONE" text has disappeared
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );

  useEffect(() => {
    if (!UpdatedLocalData) {
      // If UpdatedLocalData is empty or null, fetch PaymentMethodList and BillingAddressList from API

      return; // Exit early if there's no UpdatedLocalData
    }

    // Extract sessionPages from UpdatedLocalData
    const sessionPages = UpdatedLocalData.data.sessionPages;

    if (!sessionPages || sessionPages.length === 0) {
      // If sessionPages array is empty or not found, fetch PaymentMethodList from API

      return;
    }
    let paymentUpdated = false;
    sessionPages.forEach((page: { pageName: string; pageData: string }) => {
      try {
        if (page.pageName === "FintechName") {
          const parsedPageData = JSON.parse(page.pageData); // No need to clean since it's valid
          if (Array.isArray(parsedPageData) && parsedPageData.length > 0) {
            setSubscriptionTitle(parsedPageData[0].fintechName);
          }
        }
        if (page.pageName === "PaymentScreen") {
          const parsedPageData = JSON.parse(page.pageData);
          if (parsedPageData.PaymentData) {
            const { message } = parsedPageData.PaymentData;
            if (message === "Payment method updated successfully") {
              paymentUpdated = true;
              setisPaymentUpdate(true); // Update display text
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    });

    if (paymentUpdated) {
      // Perform any additional actions when payment has been updated
      console.log("Payment has been updated.");
    }
  }, []);

  useEffect(() => {
    const handleLinkAccountClick = () => {
      if (UpdatedLocalData) {
        const sessionPages = UpdatedLocalData.data.sessionPages;
        let successDynamicLink = null;

        for (let i = 0; i < sessionPages.length; i++) {
          if (sessionPages[i].pageNumber === 0) {
            const pageData = JSON.parse(sessionPages[i].pageData);
            successDynamicLink = pageData?.successDynamicLink;
            break;
          }
        }
        if (successDynamicLink) {
          window.location.href = successDynamicLink;
        }
      } else {
        console.error("UpdatedLocalData is not available.");
      }
    };

    // Clear browser cache
    const clearCache = async () => {
      if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map((name) => caches.delete(name)));
      }
    };

    dispatch(clearPaymentCVVs());

    // Close window
    const closeWindow = () => {
      window.close();
    };

    // Simulate animation after component mounts
    setTimeout(() => {
      setDisplayText("DONE"); // Display "DONE" after delay
      setAnimateDone(true); // Trigger animation
    }, 0); // Delay for 1 second

    // After 3 seconds, hide "DONE" text and move circle down
    setTimeout(() => {
      setDoneTextDisappear(true); // Trigger to move circle down and hide "DONE" text
    }, 2000); // Delay for 4 seconds

    // After 4.5 seconds, show the message
    setTimeout(() => {
      setShowMessage(true); // Show message
    }, 4500); // Delay for 4.5 seconds

    setTimeout(() => {
      clearCache();
      handleLinkAccountClick();
    }, 6000); // Delay for 4.5 seconds

    setTimeout(() => {
      closeWindow();
    }, 7000); // Delay for 7 seconds
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-[#705AE2] to-[#705AE2]">
      <div className="text-center">
        {doneTextDisappear ? null : ( // Render "DONE" text if doneTextDisappear is false
          <p className="text-center font-poppins font-medium text-white leading-8 mt-8">
            {displayText}
          </p>
        )}
        <div
          className={`circle-container mt-5 ${
            doneTextDisappear ? "move-down" : ""
          }`}
        >
          <div className={`circle ${animateDone ? "zoom-in" : ""}`}>
            <img src={check} className="" alt="Success Icon" />
          </div>
        </div>
        {showMessage && ( // Render if showMessage is true commenting
          <>
            <h1 className="text-white font-poppins font-bold text-[20px] leading-[32px] mt-10">
              {subscriptionTitle}
            </h1>

            <p className="text-white font-poppins animated-text mt-2">
              {isPaymentUpdate
                ? "Has been updated."
                : "Has been added to your ASA profile."}{" "}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PortfolioGoal;
