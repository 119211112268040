import { useState, useEffect } from "react";
import asavault_logo from "../../assets/Images/asavaultlogo.png";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { SessionData } from "../../types/types";
import { useDispatch } from "react-redux";
import "../../assets/css/style.css";
import { Config } from "../../Utils/AppConstants";
import { makeApiCall } from "../../services/ApiService";
import { API_CALL_TYPE, CREATE_SESSION } from "../../services/Api";

interface ExtractedParams {
  request?: string | null;
  token?: string | null;
  asaFintechCode?: string | null;
  asaFintechApplicationCode?: string | null;
  asaConsumerCode?: string | null;
  SubscriptionPlanCode?: string | null;
  IsNewSubscription?: string | null;
  IsTrialEligible?: string | null;
  Amount?: string | null;
  CouponCode?: string | null;
  PrevSubscriptionPlanCode?: string | null;
  redirecturl?: string | null;
  redirecturlonError?: string | null;
}

const Authenticate = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");

  const [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState("Authenticating...");
  const [extractedParams, setExtractedParams] = useState<ExtractedParams>({});

  const handleCreateResponse = (response: any) => {
    if (response?.data?.asaSessionCode) {
      const sessionid = response.data?.asaSessionCode;
      const consumerCode = response.data?.asaConsumerCode;
      const constructedUrl = `/portfoliopilot?token=${extractedParams.token}&sessionid=${sessionid}&asaFintechCode=${Config.asaFicode}&asaConsumerCode=${consumerCode}&version=1.08`;
      navigate(constructedUrl);
    }
  };

  const handleCreateError = () => {
    navigate("/error", { state: "PORTFOLIO AUTHENTICATION ERROR" });
  };

  useEffect(() => {
    const authUrl = new URL(window.location.href);
    // const authUrl = new URL(Config.AUTHENTICATE_URL);
    if (
      authUrl.pathname === "/authenticate" &&
      !authUrl.searchParams.toString()
    ) {
      navigate("/error");
      return;
    }

    const params = new URLSearchParams(authUrl.search);
    const newExtractedParams: ExtractedParams = {
      request: params.get("request"),
      token: params.get("token"),
      asaFintechCode: params.get("asaFintechCode"),
      asaFintechApplicationCode: params.get("asaFintechApplicationCode"),
      asaConsumerCode: params.get("asaConsumerCode"),
      SubscriptionPlanCode: params.get("SubscriptionPlanCode"),
      IsNewSubscription: params.get("IsNewSubscription"),
      IsTrialEligible: params.get("IsTrialEligible"),
      Amount: params.get("Amount"),
      CouponCode: params.get("CouponCode"),
      PrevSubscriptionPlanCode: params.get("PrevSubscriptionPlanCode"),
      redirecturl: params.get("redirecturl"),
      redirecturlonError: params.get("redirecturlonError"),
    };

    setExtractedParams(newExtractedParams);

    Config.asaConsumerCode = newExtractedParams.asaConsumerCode
      ? parseInt(newExtractedParams.asaConsumerCode, 10)
      : 0;
    Config.asaFicode = newExtractedParams.asaFintechCode
      ? parseInt(newExtractedParams.asaFintechCode, 10)
      : 0;
    Config.token=newExtractedParams.token || Config.token
    const payload = {
      asaConsumerCode: newExtractedParams.asaConsumerCode,
      version: "1.08",
      sessionName: "ASAVault",
      sessionPages: [
        {
          pageNumber: 0,
          pageName: "ASAVaultDynamicLink",
          pageUrl: "/asavault",
          pageData: JSON.stringify({
            backActionDynamicLink: newExtractedParams.redirecturl,
            successDynamicLink: newExtractedParams.redirecturl,
            linkAccountDynamicLink:
              "https://asavault.page.link/vBy5yb6hahcBNi828",
          }),
        },
        {
          pageNumber: 99,
          pageName: "FintechBillingDetails",
          pageUrl: "/fintechBillingDetails",
          pageData: JSON.stringify({
            subscriptionPlanCode: newExtractedParams.SubscriptionPlanCode,
            isNewSubscription: newExtractedParams.IsNewSubscription,
            isTrialEligible: newExtractedParams.IsTrialEligible,
            amount: newExtractedParams.Amount,
            couponCode: newExtractedParams.CouponCode,
            prevSubscriptionPlanCode:
              newExtractedParams.PrevSubscriptionPlanCode,
          }),
        },
      ],
    };

    const getSession = async () => {
      setLoading(true); // Start loading
      setTimeout(() => setStatusText("Creating ASAPAY Session..."), 3000);
      try {
        await makeApiCall(
          API_CALL_TYPE.POST_CALL,
          CREATE_SESSION(),
          handleCreateResponse,
          handleCreateError,
          "",
          "",
          payload
        );
      } catch (error) {
        handleCreateError();
      } finally {
        setLoading(false); // Stop loading
      }
    };

    getSession();
  }, [dispatch, navigate, sessionData]);

  return (
    <div className="flex bg-theme asavault_logo flex-col items-center justify-center h-screen">
      <img src={asavault_logo} alt="ASA Vault Logo" className="h-16 mb-4" />
      <p className="text-base text-white poppins-medium mb-2">{statusText}</p>
      {loading && (
        <div className="animate-spin h-8 w-8 border-t-2 border-b-2 border-white-900 rounded-full"></div>
      )}
    </div>
  );
};

export default Authenticate;
