import React, { useState,createContext,ReactNode  } from 'react';
import {IAsaSessionState} from '../types/types'
import { KeyOff } from '@mui/icons-material';
const STORAGE_KEY="AsaState"

interface Props {
  children?: ReactNode
  // any props that come into the component
}
type setStateFunc = (asaState:IAsaSessionState) => void;
type setStatePropFunc = (prop:keyof IAsaSessionState,val:any) => void;
const initialData:IAsaSessionState= JSON.parse(localStorage.getItem(STORAGE_KEY) || '0') || {}  
const AsaStateContext = createContext<[IAsaSessionState,setStateFunc]>([initialData,(s)=>{}]);
 
const AsaStateProvider = ({ children, ...props }: Props) => {
  const [state, setState] = useState(initialData);
  
  const wrapsetState=(asaState:IAsaSessionState)=>{
    localStorage.setItem(STORAGE_KEY,JSON.stringify(asaState))
    setState(asaState)
  }
  const setstateprop=(prop:keyof IAsaSessionState,val:any)=>{
    const newstate:IAsaSessionState ={...state,[prop]:val}
    wrapsetState(newstate)
  }
  return (
    <AsaStateContext.Provider value={[state,wrapsetState]}>
      {children}
    </AsaStateContext.Provider>
  );
};


export { AsaStateProvider,AsaStateContext}