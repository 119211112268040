
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import guac from "../../assets/Images/demo/guac_relative_icon.svg";
import "../../assets/css/style.css";
import { Config } from "../../Utils/AppConstants";
import bars_home from "../../assets/Images/bars_home.svg";
import LoaderComponent from "../../components/LoaderComponent";
import React  from "react";
import {useIsFetching, useQueryClient,useIsMutating } from "react-query";
import {useAsaSession} from '../../services/AsaSessionServices'

export default function GuacBase({ children, ...props }:{children:React.ReactNode,[props:string]:any}) {

  const {data:sessiondata}=useAsaSession()
  const isFetching = useIsFetching()
  const isMutating =useIsMutating()
  // Handling Update Session Error
  const isLoading=isFetching>0 || isMutating>0 
  
  const qc=useQueryClient()
  //console.log('useQueryClient ',qc)
 
  const handleBackClick = async () => {

      const firstpage=sessiondata?.sessionPages?.find((x)=>x.pageNumber===0);
      const backActionDynamicLink = firstpage && JSON.parse(firstpage.pageData).backActionDynamicLink;
      console.log('back url',backActionDynamicLink)
      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }

  };

  return (
    <div className="flex justify-center items-center overflow h-screen">
        {isLoading>0 && <LoaderComponent />}{" "}
      <div
        className="w-full h-full flex flex-col bg-gradient-guac text-white relative z-20"
        style={{
          background:
            "linear-gradient(#3ab100 0%, rgb(113, 198, 1) 7%, rgb(174, 221, 1) 20%, rgb(174, 221, 1) 100%)",
        }}
      >
        <div className="flex items-center justify-between p-4 mt-2">
          <span className="back-arrow">
            <img
              alt="back"
              
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
              onClick={(e)=>handleBackClick()}
            />
          </span>
         
        </div>

        <div
          className={`flex-grow relative rounded-t-3xl mt-6 px-6 pt-6 pb-0 flex flex-col  ${
            Config.isLight ? "bg-[#EBECF0]" : "bg-[#121212]"
          }`}
        >
          
          <div className="relative mb-2">
            <img
              alt="Guac"
              src={guac}
              className="absolute -top-10 opacity-90 left-0 z-50 w-20 h-20"
            />

            <span className="custom-span absolute left-24 -top-4 ">
              Guac
            </span>
          </div>
          <div className="flex flex-col items-center">
            {children }
          </div>
          <div className="flex flex-col flex-grow items-center">
                <img src={bars_home} className="flex subscription-barshome-img" alt="____"/>
          </div>
        </div>

      </div>

    </div>
  );
}
