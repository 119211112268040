import React from "react";
import home from "../assets/Images/home.png";
import close_small from "../assets/Images/close_small.png";

interface HomeRentProps {
  isMonthly: boolean;
  onCloseClick: () => void;
}

export default function HomeRent({ isMonthly, onCloseClick }: HomeRentProps) {
  return (
    <div
      className="w-[321px] relative mx-auto text-center flex flex-col backdrop-blur-lg bg-white/20 shadow-lg rounded-xl p-4"
      style={{ backgroundColor: "#7b43de" }}
    >
      <img
        src={close_small}
        className="absolute top-1 left-1 w-12 h-12 p-2 rounded-full focus:outline-none focus:ring focus:border-blue-300"
        onClick={onCloseClick}
        alt="Close"
      />

      <div className="flex flex-col space-y-4 pb-4">
        <div className="flex items-center mt-3 space-x-4 mx-auto">
          <img
            src={home}
            className="w-[34px] h-[34px] object-contain"
            alt="Home"
          />
          <p
            style={{ fontWeight: "" }}
            className="poppins-medium whitespace-nowrap h-[24px] text-[16px] font-poppins leading-8"
          >
            Current Rent Reporting
          </p>
        </div>

        <div className="flex mx-auto space-x-1">
          <strong
         className="text-white poppins-bold custom-font"
          >
            {isMonthly ? "$6.95" : "$49"}
          </strong>
          <span
          
            className="text-white block due-font poppins-medium" 
          >
            {isMonthly ? "/month" : "/year"}
          </span>
        </div>
      </div>
    </div>
  );
}
