// src/components/AddPaymentMethod.js

import React, { useState, useEffect } from "react";
import arrow_back_ios_new from "../../../assets/Images/arrow_back_ios_new.png";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { SessionState } from "../../../redux/sessionSlice";
import "./AddPaymentMethod.css"; // Import your CSS file for animations

const AddPaymentMethodPortfolio = () => {
  const navigate = useNavigate();
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );

  const handleLinkAccountClick = () => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let linkAccountDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          linkAccountDynamicLink = pageData.linkAccountDynamicLink;
          break;
        }
      }

      if (linkAccountDynamicLink) {
        window.location.href = linkAccountDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };

  const handleBackClick = () => {
    navigate(-1); // This will navigate back to the previous page in the browser history
  };
  const handleManualAddClick = () => {
    navigate("/AddCreditDebitCardScreen"); // Update this route to wherever you want to navigate
  };
  return (
    <div className="bg-gradient-to-b from-[#705AE2] to-[#490C95] h-screen flex flex-col items-center text-center pt-10 relative">
      <div className="absolute top-4 left-4  rounded-lg p-1 w-[40px] h-[40px] flex items-center justify-center">
        <img
          className="cursor-pointer w-[18px] h-[18px] mt-8"
          src={arrow_back_ios_new}
          alt="Back"
          onClick={handleBackClick} // Attach the click handler function here
        />
      </div>
      <h1 className="text-white font-bold text-xl ">Add a Payment Method</h1>
      <div className="bg-[#121212] rounded-t-3xl shadow-md mt-10 w-full max-w-md p-6 flex-grow flex flex-col">
        <button
          className="bg-[#705AE2] text-white box-style-add-payments  py-3 px-8 rounded-full shadow-md mb-4 mx-auto w-full max-w-[294px] h-[56px] flex flex-col justify-center items-center"
          onClick={handleLinkAccountClick} // Updated onClick handler
        >
          <span className="font-poppins font-medium  text-[15px] leading-[20px] text-center">
            Link an account
          </span>

          <span className="font-poppins text-[10px] leading-[20px] text-center">
            Updated automatically
          </span>
        </button>
        <p className="text-[#FFFFFF] mt-2 leading-4">
          <span className="button-styles-p-port  font-poppins">
            Connect to more than 10,000
          </span>
          <br />
          <span className="font-normal button-styles-p-span-meth  font-poppins">
            bank accounts, credit cards,
          </span>
          <br />
          <span className="font-normal button-styles-p-span-meth font-poppins">
            investment accounts, and more
          </span>
        </p>
        <div className="flex items-center my-10">
          <hr className="w-full border-[#4E4E4E]" />
          <span className="px-2 text-gray-500">or</span>
          <hr className="w-full border-[#4E4E4E]" />
        </div>
        <span className="manual-text-port font-poppins">
          Add a payment method manually
        </span>
        <button
          onClick={handleManualAddClick}
          className="my-6 font-poppins bg-white border-gray-300 text-gray-700 font-semibold rounded-full py-4 px-6 w-full shadow-md hover:bg-gray-100"
        >
          Manually Add
        </button>
      </div>
    </div>
  );
};

export default AddPaymentMethodPortfolio;
