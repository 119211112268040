import { env } from "../Utils/env";

export const API_CALL_TYPE: Record<string, string> = {
  GET_CALL: "GET",
  POST_CALL: "POST",
  PUT_CALL: "PUT",
  DELETE_CALL: "DELETE",
};

export const generateToken = (): string => {
  return env.REACT_APP_API_URL + `api/Authorization/Token`;
};

export const VerifyToken = (): string => {
  return env.REACT_APP_API_URL + `api/Authorization/VerifyToken`;
};

export const SAVE_SESSION = (sessionID: string): string => {
  return env.REACT_APP_API_URL + `AsaSession?asaSessionCode=${sessionID}`;
};

export const POST_CONSUMER_ADDRESS = (): string => {
  return env.REACT_APP_API_URL + `Consumer/PostConsumerAddress`;
};

export const GET_SUBSCRIPTION_PLANS = (): string => {
  return env.REACT_APP_API_URL + `Fintech/SubscriptionPlans`;
};

export const GET_ALL_FINTECHS = (): string => {
  return env.REACT_APP_API_URL + `Fintech/All`;
};

export const POST_PAYMENT_METHOD = (): string => {
  return env.REACT_APP_API_URL + `Payment/PaymentMethod`;
};

export const UPDATE_PAYMENT_METHOD_API = (): string => {
  return env.REACT_APP_API_URL + `subscriptions/UpdatePaymentMethod`;
};

export const CANCEL_SUBSCRIPTION = (): string => {
  return env.REACT_APP_API_URL + `Consumer/CancelSubscription`;
};

export const GET_ACTIVE_SUBSCRIPTION = (): string => {
  return env.REACT_APP_API_URL + `Consumer/ActiveSubscriptions`;
};

export const CREATE_SESSION = (): string => {
  return env.REACT_APP_API_URL + `AsaSession`;
};

export const GET_CONSUMER_ADDRESS = (): string => {
  return env.REACT_APP_API_URL + `Consumer`;
};

export const GET_BILLING_ADDRESSES = (): string => {
  return env.REACT_APP_API_URL + `Consumer/GetConsumerAddress`;
};

export const GET_FOREVER_BILLING = (): string => {
  return env.REACT_APP_API_URL + `subscriptions/PurchaseFreePlan`;
};

export const POST_PAYMENT_API = (): string => {
  return env.REACT_APP_API_URL + `payment/payment`;
};
export const UPDATE_SESSION = (): string => {
  return env.REACT_APP_API_URL + `AsaSession`;
};


