import { ToastContentProps, toast } from "react-toastify";

export const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };